import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["sideDrawer"]

  connect() {
    this.isOpen = this.sideDrawerTarget.dataset.sideDrawerOpen === "true"
    this.setClass()
  }

  toggle() {
    this.isOpen = !this.isOpen
    this.setClass()
  }

  close() {
    this.isOpen = false
    this.sideDrawerTarget.classList.remove("open")
  }

  setClass() {
    if (this.isOpen) {
      this.sideDrawerTarget.classList.add("open")
    } else {
      this.sideDrawerTarget.classList.remove("open")
    }
  }
}
