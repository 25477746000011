import { Controller } from "stimulus"
import tippy from "tippy.js"

export default class extends Controller {
  static values = { disabledTooltipMessage: String, placement: String }
  connect() {
    if (this.element.disabled) {
      tippy(this.element.parentElement, {
        content: this.disabledTooltipMessageValue,
        placement: this.placementValue || "top",
        animation: "scale",
        theme: "material",
      })
    }
  }
}
