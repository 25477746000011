export const serialize_custom_break_line = (content) => {
  return content.replace(
    /(?<!<(td|th)[^>]*>)\s*<p><\/p>(?!<\/(td|th)>)/g,
    '<p style="color: white;">.</p>'
  )
}

export const parse_custom_break_line = (content) => {
  return content.replace(/<p style="color: white;">.<\/p>/g, "<p></p>")
}
