import { Node, mergeAttributes } from "@tiptap/core"

export const DynamicField = Node.create({
  name: "dynamicField",
  group: "inline",
  inline: true,
  selectable: true,
  atom: true,

  addAttributes() {
    return {
      label: { default: null },
    }
  },

  parseHTML() {
    return [
      {
        tag: "span.dynamic-field",
        getAttrs: (node) => ({
          label: node.getAttribute("data-label"),
        }),
      },
    ]
  },

  renderHTML({ node, HTMLAttributes }) {
    return [
      "span",
      mergeAttributes({
        class: "dynamic-field",
        "data-label": node.attrs.label,
      }),
      `${node.attrs.label}`,
    ]
  },

  addCommands() {
    return {
      setDynamicField:
        (label) =>
        ({ commands }) => {
          return commands.insertContent({
            type: this.name,
            attrs: { label: label },
          })
        },
    }
  },
})
