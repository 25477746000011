import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["field", "counter", "error"]
  static lengthLimit = 1000

  connect() {
    this.checkLimit()
  }

  checkLimit() {
    if (this.fieldTarget.value.length > this.constructor.lengthLimit) {
      this.fieldTarget.value = this.fieldTarget.value.substring(
        0,
        this.constructor.lengthLimit
      )
      this.errorTarget.innerHTML =
        "You have exceeded the maximum number of characters."
    } else {
      this.errorTarget.innerHTML = ""
    }
    this.updateCounter()
  }

  updateCounter() {
    this.counterTarget.innerHTML = `${this.fieldTarget.value.length} / ${this.constructor.lengthLimit} characters`
  }
}
