import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["amount", "submit"]

  connect() {
    this.toggleSubmitButton()
  }

  onInput(event) {
    this.preventNonNumericInput(event)
    this.toggleSubmitButton()
  }

  preventNonNumericInput(event) {
    const value = event.target.value
    const filteredValue = value.replace(/[^0-9.,]/g, "")

    if (value !== filteredValue) {
      event.target.value = filteredValue
    }
  }

  toggleSubmitButton() {
    if (this.requiredFieldsMissing()) {
      this.submitTarget.classList.add("disabled")
      this.submitTarget.disabled = true
    } else {
      this.submitTarget.classList.remove("disabled")
      this.submitTarget.disabled = false
    }
  }

  requiredFieldsMissing() {
    let amount = this.amountTarget.value.trim()
    return amount === "" || Number(amount) === 0
  }
}
