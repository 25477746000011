import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static outlets = ["shared--side-drawer-component"]

  toggle(e) {
    this.sharedSideDrawerComponentOutlet.toggle()
  }

  submitEnd(e) {
    if (e.detail.success) {
      this.sharedSideDrawerComponentOutlet.close()
    }
  }
}
