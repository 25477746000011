import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["submitButton", "pscRadioButton"]

  connect() {
    this.submitButtonTarget.disabled = true
  }

  enableSubmit() {
    this.submitButtonTarget.disabled = false
  }
}
