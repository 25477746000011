import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [
    "forename",
    "surname",
    "submit",
    "searchAddressField",
    "searchAddressResults",
    "searchField",
    "addressFields",
    "addressLine1",
    "addressLine2",
    "addressPostcode",
    "addressPosttown",
    "addressCounty",
    "addressCountry",
  ]

  connect() {
    this.toggleSubmitButton()
    this.showSearchAddress()
  }

  onInput() {
    this.toggleSubmitButton()
  }

  toggleSubmitButton() {
    this.submitTarget.disabled = this.requiredFieldsMissing()
  }

  requiredFieldsMissing() {
    const isForenameEmpty = this.forenameTarget.value.trim() === ""
    const isSurnameEmpty = this.surnameTarget.value.trim() === ""
    return isForenameEmpty || isSurnameEmpty
  }

  handleShowSearchAddress(event) {
    event.preventDefault()
    this.showSearchAddress()
  }

  showSearchAddress() {
    this.searchAddressFieldTarget.classList.remove("d-none")
    this.addressFieldsTarget.classList.add("d-none")
  }
  showAddressFields(event) {
    event.preventDefault()
    this.searchAddressFieldTarget.classList.add("d-none")
    this.addressFieldsTarget.classList.remove("d-none")
  }

  fillAddress(event) {
    const address = JSON.parse(event.target.dataset.address)

    if (address.addressline1) {
      this.addressLine1Target.value = address.addressline1
    }

    if (address.addressline2) {
      this.addressLine2Target.value = address.addressline2
    }

    if (address.postcode) {
      this.addressPostcodeTarget.value = address.postcode
    }

    if (address.posttown) {
      this.addressPosttownTarget.value = address.posttown
    }

    if (address.county) {
      this.addressCountyTarget.value = address.county
    }

    if (address.country) {
      this.addressCountryTarget.value = address.country
    }

    this.showAddressFields(event)
  }

  searchAddress(event) {
    event.preventDefault()

    const selectedCountryOption =
      this.addressCountryTarget.options[this.addressCountryTarget.selectedIndex]

    const countryCode = selectedCountryOption.getAttribute("data-id") || 1
    const lookupParams = {
      postcode: this.searchFieldTarget.value,
      country_id: countryCode,
    }

    const headers = {
      "Content-Type": "application/json",
    }
    const params = new URLSearchParams(lookupParams).toString()
    const options = {
      method: "GET",
      headers,
    }
    fetch(`/address_lookups?${params}`, options)
      .then((response) => response.json())
      .then((data) => {
        const addresses = data.addresses || []
        this.searchAddressResultsTarget.innerHTML = addresses
          .slice(0, 10)
          .map(this.createAddressElement)
          .join("")
      })
  }

  createAddressElement(address) {
    return `
      <div class="mb-1 address-search-option" data-action="click->user-form#fillAddress" data-address='${JSON.stringify(
        address
      )}'>
        ${address.summaryline}
      </div>
    `
  }
}
