import { Controller } from "stimulus"
import { debounce } from "lodash"

export default class extends Controller {
  static targets = ["form"]

  connect() {
    this.debouncedSubmit = debounce(this.submit.bind(this), 500)
  }

  submit() {
    this.formTarget.requestSubmit()
  }
}
