import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    companyUid: String,
  }

  connect_plaid() {
    this.create_link_token()
  }

  create_link_token() {
    fetch(
      `/companies/${this.companyUidValue}/client_account/providers/plaid_integration/tokens/link_token`
    )
      .then((res) => res.json())
      .then((data) => this.handle(data.link_token))
  }

  handle(link_token) {
    const handler = Plaid.create({
      token: link_token,
      onSuccess: async (publicToken, metadata) => {
        await fetch(
          `/companies/${this.companyUidValue}/client_account/providers/plaid_integration/tokens/public_token`,
          {
            method: "POST",
            body: JSON.stringify({
              public_token: publicToken,
              metadata: metadata,
            }),
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
      },
      onEvent: (eventName, metadata) => {
        console.log("Event:", eventName)
        console.log("Metadata:", metadata)
      },
      onExit: (error, metadata) => {
        console.log(error, metadata)
      },
    })

    handler.open()
  }
}
