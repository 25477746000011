import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["shop", "submitButton"]

  static values = {
    url: String,
  }

  validateShopName() {
    const subdomainValidationRegex =
      /^[A-Za-z0-9](?:[A-Za-z0-9\-]{0,61}[A-Za-z0-9])?$/

    this.submitButtonTarget.disabled = !subdomainValidationRegex.test(
      this.shopTarget.value
    )
  }

  clickSubmit() {
    window.location.replace(
      `${this.urlValue}${this.shopTarget.value}.myshopify.com`
    )
  }
}
