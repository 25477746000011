import { Controller } from "stimulus"
import flatpickr from "flatpickr"

export default class extends Controller {
  static values = { mode: String }
  static targets = ["date", "datetime"]

  connect() {
    // Fallback to CSS class if target not found
    const dateSelector = this.hasDateTarget
      ? this.dateTarget
      : ".flatpickr-date"
    const datetimeSelector = this.hasDatetimeTarget
      ? this.datetimeTarget
      : ".flatpickr-datetime"

    flatpickr(dateSelector, {
      allowInput: true,
      mode: this.modeValue || "single",
    })

    flatpickr(datetimeSelector, {
      allowInput: true,
      enableTime: true,
      dateFormat: "Y-m-d H:i",
    })
  }
}
