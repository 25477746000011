import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    url: String,
    rutter: String,
  }
  loadQuickbooksRutter() {
    this.loadRutter("quickbooks", this.urlValue)
  }

  loadXeroRutter() {
    this.loadRutter("xero", this.urlValue)
  }

  loadAmazonRutter() {
    this.loadRutter("amazon", this.urlValue)
  }

  loadMagentoRutter() {
    this.loadRutter("magento", this.urlValue)
  }

  loadWooCommerceRutter() {
    this.loadRutter("woo_comerce", this.urlValue)
  }

  loadPrestashopRutter() {
    this.loadRutter("prestashop", this.urlValue)
  }

  loadXeroRutter() {
    this.loadRutter("xero", this.urlValue)
  }

  loadRutter(platform, callbackUrl) {
    var rutterInstance = Rutter.create({
      publicKey: this.rutterValue,
      onSuccess: function (publicToken) {
        window.location.replace(
          `${callbackUrl}?public_token=${publicToken}&platform=${platform}`
        )
      },
    })

    rutterInstance.open({ platform: platform })
  }
}
